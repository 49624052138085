import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./auth/AuthContext";

function Header() {
  const [expandBurger, setExpandBurger] = useState(false);

  const menuHandler = () => {
    setExpandBurger(!expandBurger);
  };

  return (
    <header className="bg-transparent flex justify-between items-center py-2 px-2 md:px-16">
      <Link to="/" className="">
        <div className="flex items-center justify-center gap-1">
          <p className="font-robotoflex text-2xl md:text-[40px] text-white text-4xl font-bold leading-[4.888rem]">
            nerdNinja
          </p>
          <img src="/nerdNinja-star.png" alt="Logo" className="w-11" />
        </div>
      </Link>

      <div className="hidden md:grid justify-items-center grid-flow-col content-center gap-x-10">
        <HeaderItems />
      </div>

      {/* <!-- Burger Menu Icon --> */}
      <div id="burger-menu" className="block md:hidden relative z-50">
        <span className="flex justify-end w-full">
          <button
            id="burger-btn"
            className="flex place-self-end"
            onClick={menuHandler}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 6H20M4 12H20M4 18H20"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </span>
        <div
          id="burger-dropdown"
          className={`${
            expandBurger ? "absolute top-10 right-0" : "hidden"
          } flex flex-col items-center border border-gray-500 shadow-xl rounded-xl p-4 gap-2 bg-black min-w-40`}
        >
          <HeaderItems />
        </div>
      </div>
    </header>
  );
}

const HeaderItems = () => {
  const { state } = useAuth();
  const { signOut } = useAuth();

  const user = state.user; // CognitoUser object

  const handleSignOut = () => {
    signOut();
  };

  return (
    <>
      <Link to="/#tryTtForYourself" className="flex items-center">
        <p className="font-roboto text-[#D1D1D1] text-[15px] font-medium">
          Try it Out
        </p>
      </Link>

      <Link to="/#faq" className="flex items-center">
        <p className="font-roboto text-[#D1D1D1] text-[15px] font-medium">
          FAQ
        </p>
      </Link>

      {user ? (
        <p
          className="border border-white bg-[#011D45] p-2 rounded-3xl text-white cursor-pointer"
          onClick={() => {
            handleSignOut();
          }}
        >
          Sign Out
        </p>
      ) : (
        <Link to="/login" className="flex items-center">
          <span className=" cursor-pointer flex items-center justify-center border border-white bg-[#011D45] p-2 rounded-3xl gap-2">
            <p className="text-white">Log in</p>
            <span className="w-6 aspect-square flex items-center justify-center bg-[#435875] rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
              >
                <path
                  d="M8.71541 10.2844L13 5.99983L8.71541 1.71524"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00039 5.99991L12.8801 5.99991"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </span>
        </Link>
      )}

      {user ? (
        <Link to="/myAccount" className="flex items-center">
          <p className="border border-white bg-[#011D45] px-3 py-2 rounded-3xl text-white">
            My Account
          </p>
        </Link>
      ) : (
        <Link to="/#getStarted" className="flex items-center">
          <p className="border border-white bg-[#011D45] p-2 rounded-3xl text-white">
            Get Started
          </p>
        </Link>
      )}
    </>
  );
};

export default Header;
