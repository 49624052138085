import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {useAuth} from "../components/auth/AuthContext";

interface LoginState {
    email: string;
    password: string;
}

const initialLoginState: LoginState = {
    email: "",
    password: "",
};

const Login: React.FC = () => {
    const [loginState, setLoginState] = useState<LoginState>(initialLoginState);
    const [resendEmail, setResendEmail] = useState(false);
    const navigate = useNavigate();

    const {signInToken} = useAuth();

    const [error, setError] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;

            const response = await axios.post(`${baseURL}/auth/login`, {
                email: loginState.email,
                password: loginState.password,
            });

            if (response.status === 200) {
                signInToken(response.data.idToken);
                navigate("/myAccount");
            } else {
                setError("An error occurred. Please try again.")
            }
        } catch (error) {
            let errorMessage = "An error occurred. Please try again.";

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.message || error.response?.data || errorMessage;
            } else if (error instanceof Error) {
                errorMessage = error.message || errorMessage;
            }

            if (errorMessage === "User is not confirmed.") {
                setError(errorMessage);
                setResendEmail(true);
            }

            setError(errorMessage);
        }
    };

    const handleResendEmail = async () => {
        try {
            const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;

            const response = await axios.post(`${baseURL}/auth/resend-verification-email`, {
                email: loginState.email,
            });

            if (response.status === 200) {
                alert("Email sent successfully.");
                setResendEmail(false);

            } else {
                setError("An error occurred. Please try again.")
            }
        } catch (error) {
            let errorMessage = "An error occurred. Please try again.";

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.message || error.response?.data || errorMessage;
            } else if (error instanceof Error) {
                errorMessage = error.message || errorMessage;
            }
            setError(errorMessage);
        }
    }

    return (
        <div className="min-h-screen flex flex-col items-center py-6 px-2 md:px-4 bg-black">
            {/* Header section */}
            <div className="flex justify-center">
                <div className="flex items-center space-x-2">
                    <p className="font-robotoflex text-white text-[40px] font-bold leading-[4.888rem]">
                        nerdNinja
                    </p>
                    <img src="/nerdNinja-star.png" alt="Logo" className="w-8 h-8 "/>
                </div>
            </div>

            <p className="font-roboto text-white text-5xl font-bold leading-[4.865rem]">
                Login
            </p>

            <div className="flex flex-col items-center justify-center w-full gap-8">
                <div
                    className="login-card-width flex flex-col justify-around self-center space-y-2 rounded-3xl border border-transparent opacity-80 bg-blue-900 px-7 py-5 custom-background-shadow">
                    <form className="" onSubmit={handleSubmit}>
                        <div className="rounded-md shadow-sm space-y-2">
                            <div className="relative">
                                <label
                                    htmlFor="email"
                                    className="font-roboto text-white text-xl leading-8 "
                                >
                                    Email
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    required
                                    className="block w-full p-2 rounded-lg border border-gray-400 bg-black text-white font-robotoThin text-xl input-fields"
                                    value={loginState.email}
                                    onChange={handleChange}
                                    placeholder="Enter your email"
                                />
                            </div>

                            <div className="relative">
                                <label
                                    htmlFor="password"
                                    className="font-roboto text-white text-xl leading-8 "
                                >
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    className="block w-full p-2 rounded-lg border border-gray-400 bg-black text-white font-robotoThin text-xl input-fields"
                                    value={loginState.password}
                                    onChange={handleChange}
                                    placeholder="Enter your password"
                                />
                            </div>

                            <div className="flex justify-between space-x-8">
                                <p className="text-white text-sm underline">
                                    <Link to="/forgotPassword">Forgot password</Link>
                                </p>
                                <p className="text-white text-sm underline">
                                    <Link to="/signup">Create an account</Link>
                                </p>
                            </div>
                        </div>

                        {error && (
                            <p className="font-roboto text-[#FA00FF] italic font-semibold text-center pt-2">
                                {error}
                            </p>
                        )}

                        {
                            resendEmail && (
                                <p className="font-roboto text-[#FA00FF] italic font-semibold text-center pt-2">
                                    <span onClick={handleResendEmail} className="cursor-pointer">Click here to resend the confirmation email.</span>
                                </p>
                            )
                        }

                        <div className="flex justify-center mt-5">
                            <button
                                type="submit"
                                className="w-2/5 my-account-button-shadow font-roboto self-center rounded-xl px-4 py-2 bg-[#9C00E4] text-white"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>

                <div className="login-card-width block flex items-center">
                    <hr className="w-full"/>
                    <span className="font-roboto mx-2 text-gray-400">or</span>
                    <hr className="w-full"/>
                </div>

                <div className="flex items-center">
                    <a
                        href={`${process.env.REACT_APP_IDPDOMAIN}/authorize?response_type=code&client_id=${process.env.REACT_APP_PLASMO_PUBLIC_AWSCOGNITOCLIENTID}&redirect_uri=${process.env.REACT_APP_REDIRECTURI}&identity_provider=Google`}
                        className="z-20 mx-auto sign-in-with-google px-5 py-2 flex items-center jsutify-center gap-4">
                        <img
                            src="/images/google.png"
                            alt="google"
                            className="w-6 h-6 inline"
                        />
                        <p className="font-roboto text-white text-xl font-light inline">
                            Sign in with Google
                        </p>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Login;
