import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    type ReactNode,
} from "react";
import AWS from "aws-sdk/global";
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    CognitoRefreshToken, CognitoIdToken, CognitoAccessToken,
} from "amazon-cognito-identity-js";

// AWS and Cognito configuration
const REGION = process.env.REACT_APP_PLASMO_PUBLIC_AWSREGION || "";
const USER_POOL_ID =
    process.env.REACT_APP_PLASMO_PUBLIC_AWSCOGNITOUSERPOOLID || "";
const CLIENT_ID = process.env.REACT_APP_PLASMO_PUBLIC_AWSCOGNITOCLIENTID || "";

if (!REGION || !CLIENT_ID || !USER_POOL_ID) {
    throw new Error("AWS Cognito configuration is missing");
}

AWS.config.region = REGION;
const poolData = {UserPoolId: USER_POOL_ID, ClientId: CLIENT_ID};
const cognitoUserPool = new CognitoUserPool(poolData);

interface AuthState {
    isAuthenticated: boolean;
    user: CognitoUser | null;
    isLoading: boolean;
    error: string | null;
    idToken?: string | null;
    refreshToken: string | null;
    isSubscribed: boolean | false;
}

interface AuthContextType {
    state: AuthState;
    signIn: (username: string, password: string) => void;
    signOut: () => void;
    signInToken: (token: string) => void;
    checkCurrentUser: () => void;
    getIdToken: () => string | null | undefined;
    getRefreshToken: () => string | null;
    getNewAccessToken: (refreshToken: string) => Promise<string>;
    getUserEmail: () => string | null;
    setIsSubscribed: (isSubscribed: boolean) => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const [state, setState] = useState<AuthState>({
        isAuthenticated: false,
        user: null,
        isLoading: false,
        error: null,
        idToken: null,
        refreshToken: localStorage.getItem("refreshToken") || null, // Retrieve from localStorage
        isSubscribed: false,
    });

    const setRefreshToken = (refreshToken: string | null) => {
        setState((prevState) => {
            // Update state
            const newState = {...prevState, refreshToken};
            // Update local storage
            if (refreshToken) {
                localStorage.setItem("refreshToken", refreshToken);
            } else {
                localStorage.removeItem("refreshToken");
            }
            return newState;
        });
    };

    const checkCurrentUser = () => {
        const refreshToken = "";
        // if (!refreshToken) {
        //   setState((s) => ({ ...s, isAuthenticated: false }));
        //   return;
        // }

        const cognitoUser = cognitoUserPool.getCurrentUser();
        if (!cognitoUser) {
            setState((s) => ({...s, isAuthenticated: false}));
            return;
        }

        cognitoUser.getSession(
            (err: Error | null, session: CognitoUserSession | null) => {
                if (err) {
                    console.error(err);
                    setState((s) => ({...s, error: err.message}));
                    return;
                }

                const idToken = session?.getIdToken().getJwtToken();

                const userAttributes = session?.getIdToken().payload["cognito:groups"]; // Example: Retrieve user attributes
                const isSubscribed =
                    userAttributes && userAttributes.includes("subscribed"); // Example: Check if user is subscribed

                setState({
                    isAuthenticated: !!session && session.isValid(),
                    user: session && session.isValid() ? cognitoUser : null,
                    isLoading: false,
                    error: null,
                    idToken: idToken,
                    refreshToken: refreshToken, // Update refresh token in state
                    isSubscribed: isSubscribed,
                });
            }
        );
    };

    const setIsSubscribed = (isSubscribed: boolean) => {
        setState((prevState) => ({...prevState, isSubscribed: isSubscribed}));
    };

    useEffect(() => {
        checkCurrentUser();
    }, []);

    const signInToken = async (token: string) => {
        try {
            const decodedToken = decodeJwt(token);
            setState((s) => ({
                ...s,
                isLoading: true,
                idToken: token,
                user: new CognitoUser({Username: decodedToken.email, Pool: cognitoUserPool})
            }));

            const IdToken = new CognitoIdToken({IdToken: token})
            const AccessToken = new CognitoAccessToken({AccessToken: ""})
            const RefreshToken = new CognitoRefreshToken({RefreshToken: ""})

            const session = new CognitoUserSession({IdToken, AccessToken, RefreshToken})
            state.user?.setSignInUserSession(session);

            const newRefreshToken = session.getRefreshToken()?.getToken();

            if (newRefreshToken !== undefined) {
                setRefreshToken(newRefreshToken);
            }
            checkCurrentUser();

        } catch (e) {
            setState({
                isAuthenticated: false,
                user: null,
                isLoading: false,
                error: (e instanceof Error && e.message) || e?.toString() || "An unknown error occurred",
                // idToken: null,
                refreshToken: null,
                isSubscribed: false,
            });
        }
    }

    const signIn = (username: string, password: string) => {
        setState((s) => ({...s, isLoading: true}));
        const user = new CognitoUser({Username: username, Pool: cognitoUserPool});
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: () => {
                // If authentication succeeds, update refresh token in state and local storage
                const signInUserSession = user.getSignInUserSession();
                const newRefreshToken = signInUserSession
                    ?.getRefreshToken()
                    ?.getToken();

                // Check if newRefreshToken is not undefined before setting it
                if (newRefreshToken !== undefined) {
                    setRefreshToken(newRefreshToken);
                }

                checkCurrentUser();
            },
            onFailure: (error) => {
                setState({
                    isAuthenticated: false,
                    user: null,
                    isLoading: false,
                    error: error.message || "An unknown error occurred",
                    // idToken: null,
                    refreshToken: null,
                    isSubscribed: false,
                });
            },
        });
    };

    const signOut = () => {
        setState((s) => ({...s, isLoading: true}));
        const cognitoUser = cognitoUserPool.getCurrentUser();
        if (cognitoUser) cognitoUser.signOut();
        setState({
            isAuthenticated: false,
            user: null,
            isLoading: false,
            error: null,
            idToken: null,
            refreshToken: null,
            isSubscribed: false,
        });
    };

    const getNewAccessToken = async (refreshToken: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const cognitoUser = cognitoUserPool.getCurrentUser();

            if (!cognitoUser) {
                reject(new Error("No authenticated user found."));
                return;
            }

            const refreshCognitoToken = new CognitoRefreshToken({
                RefreshToken: refreshToken,
            });

            cognitoUser.refreshSession(refreshCognitoToken, (err, session) => {
                if (err) {
                    console.error("Error refreshing session:", err);
                    reject(err);
                    return;
                }

                const accessToken = session.getAccessToken().getJwtToken();
                resolve(accessToken);
            });
        });
    };

    const getIdToken = () => {
        return state.idToken;
    };

    const getRefreshToken = () => {
        return state.refreshToken;
    };

    const getUserEmail = () => {
        const idToken = getIdToken();
        if (!idToken) return null;

        const decodedToken = decodeJwt(idToken);
        return decodedToken ? decodedToken.email : null;
    };

    const decodeJwt = (token: string) => {
        try {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map((c) => {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        } catch (e) {
            console.error("Error decoding JWT", e);
            return null;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                state,
                signIn,
                signInToken,
                signOut,
                checkCurrentUser,
                getIdToken,
                getRefreshToken,
                getNewAccessToken,
                getUserEmail,
                setIsSubscribed,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw new Error("useAuth must be used within an AuthProvider");
    return context;
};
