import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function PrivacyPolicy() {
  const p_style = "text-zinc-400 privacy-policy-p ";
  // const header_style =
  //   "py-1 font-roboto text-white text-xl font-bold leading-10";

  useEffect(() => {
    window.scrollTo(0, 0); //this ensures the page always is always on top when opening it
  }, []);

  return (
    <div className="bg-gradient bg-black min-h-screen">
      <Header />
      <div className="py-10 px-7 md:px-60">
        <h1 className="py-1 font-roboto text-white text-5xl font-bold text-center">
          Privacy Policy
        </h1>
        <h5 className="py-1 font-roboto text-gray-300 text-xl font-light text-center">
          Effective Date: 3/1/2024
        </h5>

        <div className="my-8">
          <p className={p_style}>
            nerdNinja Inc ("us", "we", or "our") operates the
            https://nerdNinja.io/ website and browser extension (hereinafter
            referred to as the "Service"). This page informs you of our policies
            regarding the collection, use and disclosure of personal data when
            you use our Service and the choices you have associated with that
            data. We use your data to provide and improve the Service. By using
            the Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions, accessible from
            https://nerdNinja.io/
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Definitions</h4>
          <h4 className={`privacy-policy-header inline`}>Service: </h4>
          <p className={`${p_style} inline`}>
            Service is the https://nerdNinja.io/ website and browser extension
            operated by nerdNinja Inc{" "}
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>Personal Data: </h4>
          <p className={`${p_style} inline`}>
            Personal Data means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>Usage Data: </h4>
          <p className={`${p_style} inline`}>
            Usage Data is data collected automatically either generated by the
            use of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>Cookies: </h4>
          <p className={`${p_style} inline`}>
            Cookies are small files stored on your device (computer or mobile
            device).
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>Data Controller:</h4>
          <p className={`${p_style} inline`}>
            Data Controller means the natural or legal person who (either alone
            or jointly or in common with other persons) determines the purposes
            for which and the manner in which any personal information are, or
            are to be, processed. For the purpose of this Privacy Policy, we are
            a Data Controller of your Personal Data.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>
            Data Processors (or Service Providers):
          </h4>
          <p className={`${p_style} inline`}>
            Data Processor (or Service Provider) means any natural or legal
            person who processes the data on behalf of the Data Controller. We
            may use the services of various Service Providers in order to
            process your data more effectively.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>
            Data Subject (or User):{" "}
          </h4>
          <p className={`${p_style} inline`}>
            Data Subject is any living individual who is using our Service and
            is the subject of Personal Data.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>
            Information Collection and Use:{" "}
          </h4>
          <p className={`${p_style} inline`}>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>User Data</h4>
          <p className={`${p_style} inline`}>
            We collect the following data from our users:
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>Personal Data: </h4>
          <p className={`${p_style} inline`}>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to: Email address
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Cookies and Usage data</h4>
          <p className={`${p_style}`}>
            We may also collect information on how the Service is accessed and
            used ("Usage Data"). This Usage Data may include information such as
            your computer's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Service that you visit, the
            time and date of your visit, the time spent on those pages, unique
            device identifiers and other diagnostic data. Tracking & Cookies
            Data: We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <h4 className={`privacy-policy-header inline`}>
            Authentication Information:{" "}
          </h4>
          <p className={`${p_style} inline`}>
            When you register for an account with us, we collect your password,
            credentials, security questions, or personal identification number
            (PIN) to authenticate your account and ensure that only authorized
            users have access to our services.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>Website Content: </h4>
          <p className={`${p_style} inline`}>
            We collect text, images, sounds, videos, or hyperlinks that you
            submit or upload to our website to provide you with our services. We
            do not sell or transfer user data to third parties, outside of the
            approved use cases. We do not use or transfer user data for purposes
            that are unrelated to our item's single purpose. We take data
            privacy seriously and are committed to safeguarding the
            confidentiality, integrity, and availability of our users' data. We
            use user data only to provide you with our services, to communicate
            with you about your account or our services, and to improve the
            quality of our services. We may also use user data to comply with
            legal obligations, resolve disputes, or enforce our agreements. We
            implement technical, administrative, and physical security measures
            to protect user data from unauthorized access, use, or disclosure.
            We also conduct periodic assessments of our security controls to
            ensure that we maintain the highest level of data security.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header inline`}>Cookies: </h4>
          <p className={`${p_style} inline`}>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service. You can instruct
            your browser to refuse all cookies or to indicate when a cookie is
            being sent. However, if you do not accept cookies, you may not be
            able to use some portions of our Service.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Use of Data</h4>
          <p className={`${p_style}`}>
            nerdNinja Inc uses the collected data for various purposes: To
            provide and maintain our Service To notify you about changes to our
            Service To allow you to participate in interactive features of our
            Service when you choose to do so To provide customer support To
            gather analysis or valuable information so that we can improve our
            Service To monitor the usage of our Service To detect, prevent and
            address technical issues
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>
            Legal Basis for Processing Personal Data under the General Data
            Protection Regulation (GDPR)
          </h4>
          <p className={`${p_style}`}>
            If you are from the European Economic Area (EEA), nerdNinja Inc
            legal basis for collecting and using the personal information
            described in this Privacy Policy depends on the Personal Data we
            collect and the specific context in which we collect it. nerdNinja
            Inc may process your Personal Data because: We need to perform a
            contract with you You have given us permission to do so The
            processing is in our legitimate interests and it is not overridden
            by your rights For payment processing purposes To comply with the
            law
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Retention of Data</h4>
          <p className={`${p_style}`}>
            nerdNinja Inc will retain your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes
            and enforce our legal agreements and policies. nerdNinja Inc will
            also retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer periods.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Transfer of Data</h4>
          <p className={`${p_style}`}>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction. Your
            consent to this Privacy Policy followed by your submission of such
            information represents your agreement to that transfer. nerdNinja
            Inc will take all the steps reasonably necessary to ensure that your
            data is treated securely and in accordance with this Privacy Policy
            and no transfer of your Personal Data will take place to an
            organisation or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Disclosure of Data</h4>
          <h4 className={`privacy-policy-header`}>Business Transaction</h4>
          <p className={`${p_style}`}>
            If nerdNinja Inc is involved in a merger, acquisition or asset sale,
            your Personal Data may be transferred. We will provide notice before
            your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>
            Disclosure for Law Enforcement
          </h4>
          <p className={`${p_style}`}>
            Under certain circumstances, nerdNinja Inc may be required to
            disclose your Personal Data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency).
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Legal Requirements</h4>
          <p className={`${p_style}`}>
            nerdNinja Inc may disclose your Personal Data in the good faith
            belief that such action is necessary to:
          </p>
          <p className={`${p_style}`}>To comply with a legal obligation</p>
          <p className={`${p_style}`}>
            To protect and defend the rights or property of nerdNinja Inc
          </p>
          <p className={`${p_style}`}>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </p>
          <p className={`${p_style}`}>
            To protect the personal safety of users of the Service or the public
          </p>
          <p className={`${p_style}`}>To protect against legal liability</p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Security of Data</h4>
          <p className={`${p_style}`}>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>
            Our Policy on "Do Not Track" Signals under the California Online
            Protection Act (CalOPPA)
          </h4>
          <p className={`${p_style}`}>
            We do not support Do Not Track ("DNT"). Do Not Track is a preference
            you can set in your web browser to inform websites that you do not
            want to be tracked. You can enable or disable Do Not Track by
            visiting the Preferences or Settings page of your web browser.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>
            Your Data Protection Rights under the General Data Protection
            Regulation (GDPR)
          </h4>
          <p className={`${p_style}`}>
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. nerdNinja Inc aims to take
            reasonable steps to allow you to correct, amend, delete or limit the
            use of your Personal Data. If you wish to be informed about what
            Personal Data we hold about you and if you want it to be removed
            from our systems, please contact us. In certain circumstances, you
            have the following data protection rights:
          </p>
          <p className={`${p_style}`}>
            The right to access, update or delete the information we have on
            you. Whenever made possible, you can access, update or request
            deletion of your Personal Data directly within your account settings
            section. If you are unable to perform these actions yourself, please
            contact us to assist you.
          </p>
          <p className={`${p_style}`}>
            The right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete. The right to object. You have the right to object to our
            processing of your Personal Data. The right of restriction. You have
            the right to request that we restrict the processing of your
            personal information.
          </p>
          <p className={`${p_style}`}>
            The right to data portability. You have the right to be provided
            with a copy of the information we have on you in a structured,
            machine-readable and commonly used format. The right to withdraw
            consent. You also have the right to withdraw your consent at any
            time where nerdNinja Inc relied on your consent to process your
            personal information.
          </p>
          <p className={`${p_style}`}>
            Please note that we may ask you to verify your identity before
            responding to such requests. You have the right to complain to a
            Data Protection Authority about our collection and use of your
            Personal Data. For more information, please contact your local data
            protection authority in the European Economic Area (EEA).
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Service Providers</h4>
          <p className={`${p_style}`}>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), provide the Service on our
            behalf, perform Service-related services or assist us in analysing
            how our Service is used. These third parties have access to your
            Personal Data only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Payments</h4>
          <p className={`${p_style}`}>
            We may provide paid products and/or services within the Service. In
            that case, we use third-party services for payment processing (e.g.
            payment processors). We will not store or collect your payment card
            details. That information is provided directly to our third-party
            payment processors whose use of your personal information is
            governed by their Privacy Policy. These payment processors adhere to
            the standards set by PCI-DSS as managed by the PCI Security
            Standards Council, which is a joint effort of brands like Visa,
            MasterCard, American Express and Discover. PCI-DSS requirements help
            ensure the secure handling of payment information. The payment
            processors we work with are: Stripe Their Privacy Policy can be
            viewed at https://stripe.com/us/privacy
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Links to Other Sites</h4>
          <p className={`${p_style}`}>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit. We have no control over and assume
            no responsibility for the content, privacy policies or practices of
            any third party sites or services.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Children's Privacy</h4>
          <p className={`${p_style}`}>
            Our Service does not address anyone under the age of 18
            ("Children"). We do not knowingly collect personally identifiable
            information from anyone under the age of 18. If you are a parent or
            guardian and you are aware that your Child has provided us with
            Personal Data, please contact us. If we become aware that we have
            collected Personal Data from children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>
            Changes to This Privacy Policy
          </h4>
          <p className={`${p_style}`}>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy. You are advised
            to review this Privacy Policy periodically for any changes. Changes
            to this Privacy Policy are effective when they are posted on this
            page.
          </p>
        </div>

        <div className="my-4">
          <h4 className={`privacy-policy-header`}>Contact Us</h4>
          <p className={`${p_style}`}>
            If you have any questions about this Privacy Policy, please contact
            us at james@nerdninja.io
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
