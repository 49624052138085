import React, { CSSProperties, useEffect, useRef, useState } from "react";

interface Props {
  className?: string;
  videoURL: string;
  style?: CSSProperties;
}

const VideoPlayer: React.FC<Props> = ({ className, videoURL, style }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoError, setVideoError] = useState<boolean>(false);

  const handleContextMenu = (
    e: React.MouseEvent<HTMLVideoElement, MouseEvent>
  ) => {
    e.preventDefault(); // Prevent the default context menu
  };

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.muted = true;
      video.autoplay = true;
      video.loop = true;
      video.controls = false;

      // Event listener to handle video load error
      video.addEventListener("error", handleVideoError);
    }

    return () => {
      // Cleanup on unmount
      if (video) {
        video.removeEventListener("error", handleVideoError);
      }
    };
  }, []);

  const handleVideoError = () => {
    // Set video error state to true if video fails to load
    setVideoError(true);
  };

  return (
    <>
      {videoError ? (
        <p className="text-red-800">Sorry, the video failed to load.</p>
      ) : (
        <video
          ref={videoRef}
          src={videoURL}
          className={className}
          style={style}
          //   autoPlay
          //   loop
          playsInline
          onContextMenu={handleContextMenu} // Disable right-click context menu
        />
      )}
    </>
  );
};

export default VideoPlayer;
