import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../components/auth/AuthContext";

const Verification: React.FC = () => {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  // const { signIn } = useAuth();

  const navigate = useNavigate();

  let index = 0;

  const onSubmit = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const code = urlParams.get("code") ? urlParams.get("code") : "";
    const email = urlParams.get("email") ? urlParams.get("email") : "";

    if (!email || !code) return;

    try {
      index++;
      setLoading(true);

      const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;

      console.log("baseURL - ", baseURL);

      const response = await axios.post(
        `${baseURL}/auth/verify-email-code`,
        {
          Email: email,
          Code: code,
        }
      );

      if (response.data.status === 400) {
        if (response.data.err.code === "ExpiredCodeException") {
          setErr("Code is expired.");
        } else {
          setErr("Email verification failed.");
        }
        setLoading(false);
      } else {
        setErr("");
        setLoading(false);
        alert("Your email address is verified successfully.");
        navigate("/login");
        // navigate("/myAccount");
      }
    } catch (error) {
      console.log("catch block error - ", error);
    }
  };

  useEffect(() => {
    console.log("useEffect called")
    if (index === 0) onSubmit();
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center py-6 px-4 sm:px-6 lg:px-8 bg-black">
      {/* Header section */}
      <div className="flex justify-center">
        <div className="flex items-center space-x-2">
          <p className="font-robotoflex text-white text-[40px] font-bold leading-[4.888rem]">
            nerdNinja
          </p>
          <img src="/nerdNinja-star.png" alt="Logo" className="w-8 h-8 " />
        </div>
      </div>

      <p className="font-roboto text-white text-5xl font-bold leading-[4.865rem]">
        Sign up
      </p>

      <div className="login-card-width rounded-3xl border border-transparent opacity-80 bg-blue-900 px-5 py-4 flex flex-col items-center justify-around gap-6 custom-background-shadow">
        <img src="/images/check.png" alt="Logo" className="w-20 h-20 " />

        <p className="text-center text-white font-roboto text-xl font-bold">
          Account Created!
        </p>

        <p className="text-center text-white font-roboto text-xl">
          Check your email to complete login
        </p>
      </div>

      <div className="auth_content">
        {loading && (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 70 }}
          >
            <p className="text-whtie text-4xl">Loading ...</p>
          </div>
        )}
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 70 }}
        >
          {err && <p className="text-white err">{err}</p>}
        </div>
      </div>
    </div>
  );
};

export default Verification;
