import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth } from "../components/auth/AuthContext";
// import { useLocation } from "react-router-dom";
import SkeletonLoading from "../components/SkeletonLoading";
import { jwtDecode } from "jwt-decode";

function MyAccount() {
  const { state } = useAuth();
  const {
    getUserEmail,
    getIdToken,
    setIsSubscribed,
    getRefreshToken,
    getNewAccessToken,
  } = useAuth();

  // const isAuthenticated = state.isAuthenticated;
  let user = state.user; // CognitoUser object
  const isLoading = state.isLoading;
  // const error = state.error;
  let isSubscribed = state.isSubscribed;
  let userEmail = getUserEmail();
  let idToken = getIdToken();

  const [isCurrentPlanLoading, setIsCurrentPlanLoading] = useState(true);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");

  const [checkoutComplete, setCheckoutComplete] = useState(false);

  const [isActivateBtnLoading, setIsActivateBtnLoading] = useState(false);
  const [isActivateBtnError, setIsActivateBtnError] = useState("");

  // Call this function when the user comes back from the Stripe checkout flow
  const handleCheckoutComplete = () => {
    setCheckoutComplete((prevState) => !prevState);
  };

  useEffect(() => {
    checkSubscriptionHandler();
  }, [userEmail]);

  const isTokenValid = (token: any) => {
    if (!token) return false;

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    // return decodedToken && decodedToken.exp && decodedToken.exp > currentTime;

    if (
      decodedToken &&
      typeof decodedToken.exp !== "undefined" &&
      decodedToken.exp > currentTime
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkSubscriptionHandler = async () => {
    if (!userEmail) {
      setIsCurrentPlanLoading(false);
      return;
    }
    setIsCurrentPlanLoading(true);

    try {
      const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;
      let accessToken = getIdToken();

      // Check if accessToken is expired or not available
      if (!isTokenValid(accessToken)) {
        const refreshToken = getRefreshToken();

        if (!refreshToken) {
          setIsCurrentPlanLoading(false);
          return;
        }

        accessToken = await getNewAccessToken(refreshToken);

        if (!accessToken) {
          setIsCurrentPlanLoading(false);
          return;
        }
      }

      const response = await axios.get(`${baseURL}/subscription/${userEmail}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status !== 200) {
        setIsCurrentPlanLoading(false);
        console.error(
          "Failed to fetch subscription details:",
          response.statusText
        );
        return;
      }

      const productKey1 = process.env.REACT_APP_STRIPE_PRODUCT_KEY_1;
      const productKey2 = process.env.REACT_APP_STRIPE_PRODUCT_KEY_2;
      const productKey3 = process.env.REACT_APP_STRIPE_PRODUCT_KEY_3;
      const productKey4 = process.env.REACT_APP_STRIPE_PRODUCT_KEY_4;

      if (response.data.length === 0) {
        setIsSubscribed(false);
        setIsCurrentPlanLoading(false);
        return;
      }

      const plan = response.data[0].items[0].plan;

      if (
        plan.active === true &&
        (plan.productId === productKey1 ||
          plan.productId === productKey2 ||
          plan.productId === productKey3 ||
          plan.productId === productKey4)
      ) {
        const subPlan = `$${plan.amount / 100} / ${plan.interval}`;

        setSubscriptionPlan(subPlan);
        setIsSubscribed(true);
      }

      setIsCurrentPlanLoading(false);
    } catch (error) {
      console.error("checkSubscription Error - ", error);
    }
  };

  const successUrl = process.env.REACT_APP_STRIPE_SUBSCRIPTION_SUCCESS_URL;
  const cancelUrl = process.env.REACT_APP_STRIPE_SUBSCRIPTION_CANCEL_URL;
  const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;

  const activateSubscriptionHandler = async () => {
    setIsActivateBtnLoading(true);
    try {
      if (!idToken) {
        setIsActivateBtnError("An error occurred. Please try again.");
      }

      const response = await axios.post(
        `${baseURL}/subscription/checkout`,
        {
          successUrl: successUrl,
          cancelUrl: cancelUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.status === 200) {
        handleCheckoutComplete();
        setIsActivateBtnLoading(false);
        window.location.href = response.data.url;
      } else {
        setIsActivateBtnLoading(false);
        setIsActivateBtnError(
          `Failed to activate subscription: ${response.statusText}`
        );
        // console.error("Failed to activate subscription: ", response.statusText);
      }
    } catch (error) {
      setIsActivateBtnLoading(false);

      let errorMessage = "An error occurred. Please try again.";

      if (axios.isAxiosError(error)) {
        errorMessage =
          error.response?.data?.message || error.response?.data || errorMessage;
      } else if (error instanceof Error) {
        errorMessage = error.message || errorMessage;
      }

      setIsActivateBtnError(errorMessage);
    }
  };

  // const manageSubscriptionHandler = async () => {
  //   try {
  //
  //   } catch (error) {
  //     console.log("Error -- ", error);
  //   }
  // };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gradient bg-black">
      <Header />
      <div className="pb-8 grow flex flex-col items-center space-y-6">
        <div className="flex justify-center">
          <img src="/nerdNinja-star.png" alt="Logo" className="w-24 md:w-28" />
        </div>

        <div className="w-3/4 md:w-1/2 lg:w-1/3 my-account-card-shadow flex flex-col bg-[#282828] opacity-100 justify-around rounded-3xl px-4 md:px-6 pt-6 space-y-8">
          <h2 className="text-white font-roboto text-2xl md:text-4xl font-semibold">
            My Account
          </h2>

            <div className="pb-4 md:pb-5 ">
            <span className="flex justify-between">
              <p className="text-white font-roboto text-xl md:text-2xl ">
                Email:
              </p>
              <p className="text-white font-robotoLight text-xl md:text-2xl">
                {userEmail ? (
                    userEmail
                ) : isLoading ? (
                    <SkeletonLoading/>
                ) : (
                    "user@email.com"
                )}
              </p>
            </span>
                <span className="flex justify-between">
              <p className="text-white font-roboto text-xl md:text-2xl">
                Current Plan:
              </p>
              <span className="">
                {isCurrentPlanLoading ? (
                    <SkeletonLoading/>
                ) : isSubscribed ? (
                    <span className="flex flex-col items-center justify-around">
                    <p className="text-[#04D14D] font-roboto text-xl md:text-2xl leading-6 font-bold">
                      Subscribed
                    </p>
                    <p className="text-white text-xl font-light leading-6">
                      {subscriptionPlan}
                    </p>
                  </span>
                ) : (
                    <p className="text-[#FF4E4E] font-roboto text-xl md:text-2xl font-bold">
                        Unsubscribed
                    </p>
                )}
              </span>
            </span>
            <span className="flex justify-center items-center mt-6">
              <a className={"text-[#AE01FF] underline"} href="https://www.youtube.com/watch?v=DId59UfcCmk" target="_blank">Get Started Tutorial Video</a>
            </span>
            </div>
        </div>

          <div className="w-full flex flex-col items-center">
              {isSubscribed ? (
                  <a
                      href="https://billing.stripe.com/p/login/cN2cPQb8y6LNfPq144"
              className="py-4 px-6 md:px-12 rounded-lg bg-purple-500 text-white text-xl md:text-2xl font-roboto font-bold"
              // onClick={() => {
              //   manageSubscriptionHandler();
              // }}
              // disabled={!user}
            >
              Manage Subscription
            </a>
          ) : (
            <button
              className={`py-4 px-6 md:px-12 rounded-lg bg-[#00A93C] text-white text-xl md:text-2xl font-roboto font-bold
                ${isActivateBtnLoading ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={() => {
                activateSubscriptionHandler();
              }}
              disabled={!user}
            >
              Activate Subscription
            </button>
          )}

          {isActivateBtnError && (
            <p className="w-full font-roboto text-[#FA00FF] italic font-semibold text-center ">
              {isActivateBtnError}
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MyAccount;
