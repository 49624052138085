import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

interface SignUpState {
  email: string;
  password: string;
  confirmPassword: string;
}

const initialSignUpState: SignUpState = {
  email: "",
  password: "",
  confirmPassword: "",
};

// interface ErrorResponse {
//   message: string;
// }

function SignUp() {
  const navigate = useNavigate();

  const [signUpState, setSignUpState] =
    useState<SignUpState>(initialSignUpState);

  const [error, setError] = useState("");
  const [agreedTermsAndServices, setAgreedTermsAndServices] = useState(false);

  const handleCheckboxChange = () => {
    setAgreedTermsAndServices(!agreedTermsAndServices);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignUpState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (signUpState.password !== signUpState.confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      if (!agreedTermsAndServices) {
        setError("Please agree to the terms and conditions.");
        return;
      }

      const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;

      const response = await axios.post(`${baseURL}/auth/register`, {
        Username: signUpState.email,
        Email: signUpState.email,
        Password: signUpState.password,
      });

      if (response.status === 200) {
        navigate("/Verification");
      } else {
        setError("An error occurred. Please try again.");
      }
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";

      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.message || error.response?.data || errorMessage;
      } else if (error instanceof Error) {
        errorMessage = error.message || errorMessage;
      }

      setError(errorMessage);
    }
  };

  // Render the signup form if form is not submitted
  return (
    <div className="min-h-screen flex flex-col items-center py-6 px-4 sm:px-6 lg:px-8 bg-black">
      {/* Header section */}

      <div className="flex justify-center">
        <div className="flex items-center space-x-2">
          <p className="font-robotoflex text-white text-[40px] font-bold leading-[4.888rem]">
            nerdNinja
          </p>
          <img src="/nerdNinja-star.png" alt="Logo" className="w-8 h-8 " />
        </div>
      </div>

      <p className="font-roboto text-white text-5xl font-bold leading-[4.865rem]">
        Sign up
      </p>

      <div className="flex flex-col items-center justify-center w-full gap-8">
        <div className="login-card-width flex flex-col justify-around self-center space-y-2 rounded-3xl border border-transparent opacity-80 bg-blue-900 px-7 py-5 custom-background-shadow">
          <p className="font-roboto text-center text-white text-xl leading-5">
            Already a member?{" "}
            <Link to="/login" className="text-[#AE01FF] font-bold underline">
              Log in
            </Link>
          </p>
          <form className="w-full" onSubmit={handleSignup}>
            <div className="w-full rounded-md shadow-sm space-y-2">
              <div className="relative">
                <label
                  htmlFor="email"
                  className="font-roboto text-white text-xl leading-8 "
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  required
                  className="block w-full p-2 rounded-lg border border-gray-400 bg-black text-white font-robotoThin text-xl input-fields"
                  value={signUpState.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
              </div>

              <div className="relative">
                <label
                  htmlFor="password"
                  className="font-roboto text-white text-xl leading-8 "
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="block w-full p-2 rounded-lg border border-gray-400 bg-black text-white font-robotoThin text-xl input-fields"
                  value={signUpState.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />
              </div>

              <div className="relative">
                <label
                  htmlFor="password"
                  className="font-roboto text-white text-xl leading-8 "
                >
                  Confirm Your Password
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  className="block w-full p-2 rounded-lg border border-gray-400 bg-black text-white font-robotoThin text-xl input-fields"
                  value={signUpState.confirmPassword}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />
              </div>

              <div className="flex gap-1 px-4 pt-1">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-indigo-600"
                  checked={agreedTermsAndServices}
                  onChange={handleCheckboxChange}
                  id="termsAgreement"
                />
                <label
                  htmlFor="termsAgreement"
                  className="font-roboto text-white text-[13px] font-extralight "
                >
                  {`I agree to and have read the `}
                  <Link to="/termsAndServices" className="underline">
                    Terms of Service
                  </Link>
                  {" and "}
                  <Link to="/privacyPolicy" className="underline">
                    Privacy Policy
                  </Link>
                </label>
              </div>
            </div>

            {error && (
              <p className="font-roboto text-[#FA00FF] italic font-semibold text-center pt-2">
                {error}
              </p>
            )}

            <div className="flex justify-center py-2">
              <button
                type="submit"
                className="my-account-button-shadow font-roboto self-center rounded-xl px-8 py-2 bg-[#9C00E4] text-white"
              >
                Create account
              </button>
            </div>
          </form>
        </div>

        {/* <div className="login-card-width block flex items-center">
          <hr className="w-full" />
          <span className="font-roboto mx-2 text-gray-400">or</span>
          <hr className="w-full" />
        </div>

        <div className="flex items-center">
          <button className="z-20 mx-auto sign-in-with-google px-5 py-2 flex items-center jsutify-center gap-4">
            <img
              src="/images/google.png"
              alt="google"
              className="w-6 h-6 inline"
            />
            <p className="font-roboto text-white text-xl font-light inline">
              Sign in with Google
            </p>
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default SignUp;
