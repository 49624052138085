import React from "react";

const SkeletonLoading = () => {
  return (
    <span
      role="status"
      className="inline-block h-5 animate-pulse bg-purple-500 w-20 rounded-full "
    ></span>
  );
};

export default SkeletonLoading;
