import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

interface ForgotPasswordInputState {
  email: string;
}

const initialForgotPasswordInputState: ForgotPasswordInputState = {
  email: "",
};

function ForgotPassword() {
  const [forgotPasswordInputState, setForgotPasswordInputState] =
    useState<ForgotPasswordInputState>(initialForgotPasswordInputState);

  const [submitted, setSubmitted] = useState(false); 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForgotPasswordInputState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePasswordForgot = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    try {
      const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;

      const response = await axios.post(
        `${baseURL}/auth/forgot-password`,
        { email: forgotPasswordInputState.email }
      ); 

      if (response.status === 200) {
        setSubmitted(true);
      } else {
        console.error("Failed to sign in:", response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Render the CheckEmail component if form is submitted
  if (submitted) {
    return <CheckEmail />;
  }

  // Render the signup form if form is not submitted
  return (
    <div className="min-h-screen flex flex-col items-center py-6 px-4 sm:px-6 lg:px-8 bg-black">
      {/* Header section */}
      <div className="flex justify-center">
        <div className="flex items-center space-x-2">
          <p className="font-robotoflex text-white text-[40px] font-bold leading-[4.888rem]">
            nerdNinja
          </p>
          <img src="/nerdNinja-star.png" alt="Logo" className="w-8 h-8 " />
        </div>
      </div>

      <div className="w-1/4 my-4">
        <Link to="/login" className="font-roboto text-white text-xl font-bold">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="30"
            viewBox="0 0 38 30"
            fill="none"
          >
            <path
              d="M36 17C37.1046 17 38 16.1046 38 15C38 13.8954 37.1046 13 36 13L36 17ZM0.585785 13.5858C-0.195263 14.3668 -0.195263 15.6332 0.585785 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857863C15.3611 0.0768138 14.0948 0.0768137 13.3137 0.857862L0.585785 13.5858ZM36 13L2 13L2 17L36 17L36 13Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>

      <p className="font-roboto text-white text-3xl md:text-4xl lg:text-5xl font-bold leading-[4.865rem]">
        Reset Password
      </p>

      <div className="login-card-width rounded-3xl border border-transparent opacity-80 bg-blue-900 px-5 py-2 custom-background-shadow">
        <form
          className="flex flex-col space-y-4 p-2"
          onSubmit={handlePasswordForgot}
        >
          <div className="rounded-md shadow-sm space-y-2">
            <div className="relative">
              <label
                htmlFor="email"
                className="font-roboto text-white text-xl leading-8 "
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="text"
                required
                className="block w-full px-3 py-2 rounded-lg border border-gray-400 bg-black placeholder-gray-400 text-white input-fields"
                value={forgotPasswordInputState.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
            </div>
          </div>

          <div className="flex justify-center py-2">
            <button
              type="submit"
              className="my-account-button-shadow font-roboto self-center rounded-xl px-8 py-2 bg-[#9C00E4] text-white"
            >
              Send Reset Link
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const CheckEmail: React.FC = () => {
  // // Delete this login its used just as an example now
  // const location = useLocation();

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     // Replace '/destination' with the path you want to navigate to
  //     window.location.href = "/dashboard";
  //   }, 2000); // Wait for 2000 milliseconds (2 seconds)

  //   // Clean up the timeout to avoid memory leaks
  //   return () => clearTimeout(timeout);
  // }, [location]);
  // //
  // //
  // //

  return (
    <div className="min-h-screen flex flex-col items-center py-6 px-4 sm:px-6 lg:px-8 bg-black">
      {/* Header section */}
      <div className="flex justify-center">
        <div className="flex items-center space-x-2">
          <p className="font-robotoflex text-white text-[40px] font-bold leading-[4.888rem]">
            nerdNinja
          </p>
          <img src="/nerdNinja-star.png" alt="Logo" className="w-8 h-8 " />
        </div>
      </div>

      <div className="w-1/4 my-4">
        <Link to="/login" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="30"
            viewBox="0 0 38 30"
            fill="none"
          >
            <path
              d="M36 17C37.1046 17 38 16.1046 38 15C38 13.8954 37.1046 13 36 13L36 17ZM0.585785 13.5858C-0.195263 14.3668 -0.195263 15.6332 0.585785 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857863C15.3611 0.0768138 14.0948 0.0768137 13.3137 0.857862L0.585785 13.5858ZM36 13L2 13L2 17L36 17L36 13Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>

      <p className="font-roboto text-white text-3xl md:text-4xl lg:text-5xl font-bold leading-[4.865rem]">
        Reset Password
      </p>
      <div className="login-card-width rounded-3xl border border-transparent opacity-80 bg-blue-900 px-5 py-4 flex flex-col items-center justify-around gap-6 custom-background-shadow">
        <img src="/images/check.png" alt="Logo" className="w-20 h-20 " />
        <p className="text-white font-roboto text-xl">
          Please check your email
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
