import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/Login";
import SignupPage from "./pages/SignUp";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndServices from "./pages/TermsOfService";
import EmailTheCEO from "./pages/EmailTheCEO";
import MyAccount from "./pages/MyAccount";
import ResetPassword from "./pages/ResetPassword";
import { AuthProvider } from "./components/auth/AuthContext";
import Verification from "./pages/Verification";
import ForgotPassword from "./pages/ForgotPassword";
import SSOCallback from "./pages/SSOCallback";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sso/callback" element={<SSOCallback />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsAndServices" element={<TermsAndServices />} />
          <Route path="/emailTheCEO" element={<EmailTheCEO />} />
          <Route path="/myAccount" element={<MyAccount />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
