import {useAuth} from "../components/auth/AuthContext";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import axios from 'axios';

const CLIENT_ID = process.env.REACT_APP_PLASMO_PUBLIC_AWSCOGNITOCLIENTID || "";
const REDIRECT_URI = process.env.REACT_APP_REDIRECTURI || "";
const IDP_DOMAIN = process.env.REACT_APP_IDPDOMAIN || "";

const SSOCallback: React.FC = () => {
    const {signInToken} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        if (code) {
            const data = new URLSearchParams();
            data.append('grant_type', 'authorization_code');
            data.append('client_id', CLIENT_ID);
            data.append('redirect_uri', REDIRECT_URI);
            data.append('code', code);

            axios.post(IDP_DOMAIN + "/token", data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                const idToken = response.data.id_token;
                signInToken(idToken);
                navigate("/myAccount");
            }).catch(e => {
            }) // Currently this gets called twice... ignore error
        }

    }, []);

    return (
        <div className="bg-gradient bg-black">
            <div className="flex flex-col min-h-screen blur-none bg-transparent">
            </div>
        </div>
    );
}

export default SSOCallback;