import React, { useState } from "react";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`rounded-3xl border border-solid border-2 h-min min-w-full ${
        isOpen
          ? "bg-[#AE01FF] border-[#D55C12]"
          : "bg-[#011D45] border-[#5F7FC0]"
      } `}
    >
      <button
        className={`flex justify-between items-center w-full p-4 md:p-6 text-left text-white focus:outline-none `}
        onClick={toggleAccordion}
      >
        <span className="font-roboto font-semibold text-[22px]">
          <span className={`px-2 ${isOpen ? "text-white" : "text-[#D55C12]"}`}>
            Q:
          </span>
          <span className="text-white">{question}</span>
        </span>

        <span className="text-xl">{isOpen ? " - " : "+"}</span>
      </button>

      {isOpen && (
        <div className="py-4 px-6 bg-black rounded-b-3xl ">
          <p className="font-roboto font-light text-2xl text-white">{answer}</p>
        </div>
      )}
    </div>
  );
};

export default FAQItem;
