import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-black block p-4 lg:p-10 grid grid-cols-3 lg:grid-cols-4 gap-4">
      <div className="col-span-3 lg:col-span-1">
        <span className="space-x-2 flex items-center ">
          <p className="font-robotoflex text-white font-bold text-[40px] leading-[4.888rem] inline">
            nerdNinja
          </p>
          <img src="/nerdNinja-star.png" alt="Logo" className="inline w-11 " />
        </span>
        <div className="flex gap-2 my-2">
          <a
            href="https://www.tiktok.com/@jamesleonidas2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/tiktok.png" alt="TikTok Logo" className="w-8 h-8 " />
          </a>
          <a
            href="https://www.instagram.com/jamesleonidas2/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/instagram.png"
              alt="Instagram Logo"
              className="w-8 h-8 "
            />
          </a>
        </div>
      </div>

      <div className="col-span-1">
        <p className="font-robotoflex text-white text-2xl font-bold leading-[2.933rem] cursor-pointer">
          Product
        </p>
        <p className="font-robotoflex text-white text-xl font-extralight leading-10 cursor-pointer">
          <Link to="/#getStarted">Pricing</Link>
        </p>
        <a
          href="https://chromewebstore.google.com/detail/nerdninja/ndomankjolllpbijlfdgngibidghflln"
          target="_blank"
          rel="noopener noreferrer"
          className="font-robotoflex text-white text-xl font-extralight leading-10 cursor-pointer"
        >
          Chrome Web Store
        </a>
      </div>

      <div className="col-span-1">
        <p className="font-robotoflex text-white text-2xl font-bold leading-[2.933rem] cursor-pointer">
          Company
        </p>
        <p className="font-robotoflex text-white text-xl font-extralight leading-10 cursor-pointer">
          <Link to="/privacyPolicy">Privacy Policy</Link>
        </p>
        <p className="font-robotoflex text-white text-xl font-extralight leading-10 cursor-pointer">
          <Link to="/termsAndServices">Terms of Service</Link>
        </p>
      </div>

      <div className="col-span-1">
        <p className="font-robotoflex text-white text-2xl font-bold leading-[2.933rem] cursor-pointer">
          Help
        </p>
        <p className="font-robotoflex text-white text-xl font-extralight leading-10 cursor-pointer">
          <Link to="/emailTheCEO">Email the CEO</Link>
        </p>
        <p className="font-robotoflex text-white text-xl font-extralight leading-10 cursor-pointer">
          <Link to="/#faq">FAQ</Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
