import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";

interface CEOMessageState {
  email: string;
  message: string;
}

const initialCEOMessageState: CEOMessageState = {
  email: "",
  message: "",
};

const EmailTheCEO: React.FC = () => {
  const [ceoMessageState, setCEOMessageState] = useState<CEOMessageState>(
    initialCEOMessageState
  );
  const navigate = useNavigate();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setCEOMessageState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const baseURL = process.env.REACT_APP_AZURE_WEBSITE_BASE_URL;

      if (!ceoMessageState.email || !ceoMessageState.message) return;

      const response = await axios.post(`${baseURL}/email/send`, {
        from: ceoMessageState.email,
        to: process.env.REACT_APP_EMAIL_THE_CEO_RECIPIENT_EMAIL,
        subject: "Email the CEO",
        body: ceoMessageState.message,
      });

      if (response.status === 200) {
        alert("Email successfully sent.");
        setCEOMessageState({
          email: "",
          message: "",
        });
        navigate("/emailTheCEO");
      } else {
        console.error("Failed to sign in:", response.statusText);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <div className="bg-gradient bg-black">
      <Header />
      <div className=" flex flex-col items-center py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="font-roboto text-center text-white text-5xl font-bold">
          What’s good? What’s bad?
        </h1>

        <h4 className="font-robotoflex text-[#979797] text-4xl font-light">
          Let it all out.
        </h4>

        <div className="py-8 w-full flex-grow grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-start-3 lg:col-span-2">
            <div className="relative mt-10 md:mt-0 mx-auto rounded-xl shadow-md rounded-lg border-4 border-[#484848] bg-[#1D1D1D] w-64 lg:w-52">
              <div className="absolute top-0 left-0">
                <img
                  className="h-24 w-24 -translate-x-1/2 -translate-y-1/2 rounded-full object-cover "
                  src="/images/Group 39389.png"
                  alt="Profile"
                />
              </div>

              <div className="pt-6 px-4 pb-2 space-y-4">
                <p className="font-roboto text-white text-xl leading-[1.25rem] text-indent">
                  James here - we’re always looking to make nerdNinja the
                  <span className="text-[#AE01FF]">
                    {" "}
                    best possible user experience.
                  </span>{" "}
                  For that reason, your feedback goes directly to my inbox where
                  I then pass along fixes and features to our developers.{" "}
                </p>
                <p className="font-roboto text-white text-xl leading-[2.25rem]">
                  Cheers.
                </p>
              </div>
            </div>
          </div>

          <form
            className="col-span-12 lg:col-span-4 self-center space-y-6 rounded-3xl border border-transparent opacity-80 px-10 py-2"
            onSubmit={handleSubmit}
          >
            <div className="rounded-md shadow-sm space-y-3 p-8 custom-card">
              <div className="relative space-y-1">
                <label
                  htmlFor="email"
                  className="font-roboto text-xl text-white"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  required
                  className="block w-full p-4 rounded-lg border border-gray-400 bg-black text-white font-robotoThin text-xl input-fields"
                  value={ceoMessageState.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
              </div>
              <div className="relative space-y-1">
                <label
                  htmlFor="message"
                  className="font-roboto text-xl text-white"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  className="block w-full px-3 py-2 rounded-lg border border-gray-400 bg-black bg-opacity-75 placeholder-gray-400 text-white font-robotoThin text-xl input-fields focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-transparent resize-none"
                  value={ceoMessageState.message}
                  onChange={handleChange}
                  placeholder="What’s on your mind?"
                  rows={8}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="w-4/5 self-center rounded px-10 md:px-14 py-4 bg-[#9C00E4] font-roboto text-xl md:text-2xl text-white font-bold "
              >
                Send to CEO
              </button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EmailTheCEO;
