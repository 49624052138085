import React from "react";

interface SVGProps {
  className?: string;
  width?: number;
  height?: number;
}

const DefaultSVGWidth = 22;
const DefaultSVGHeight = 18;

const RightArrow: React.FC<SVGProps> = ({
  className,
  width = DefaultSVGWidth,
  height = DefaultSVGHeight,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M14.0504 16.635L20.7426 9.37028L14.0504 2.10556"
        stroke="white"
        strokeWidth="2.34615"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00001 9.37036L20.5552 9.37036"
        stroke="white"
        strokeWidth="2.34615"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightArrow;
