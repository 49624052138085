import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import FAQItem from "../components/FAQItem";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import RightArrow from "../assets/rightArrow";
import VideoPlayer from "../components/VideoPlayer";

function HomePage() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <div className="bg-gradient bg-black">
      <div className="flex flex-col min-h-screen blur-none bg-transparent">
        <Header />
        <main className="flex-grow">
          <LandingSection />
          <Testimonial />
          <TryitForYourself />
          <GetStartedSection />
          <Carousel />
          <Section2 />
          <FAQ />
        </main>
        <Footer />
      </div>
    </div>
  );
}

const LandingSection: React.FC = () => {
  return (
    <section id="LandingSection" className="flex flex-col items-center pb-32">
      <img src="/nerdNinja-star.png" alt="Logo" className="w-28 mb-4" />

      <h2 className="landing-header-shadow font-roboto text-4xl md:text-5xl text-white text-center font-bold">
        Screenshot a Question. Get the Answer.
      </h2>

      <h3 className="font-robotoflex text-2xl md:text-[40px] text-[#B6B6B6] text-center font-light">
        Your Personal AI Problem Solver
      </h3>

      <div className="relative my-5 w-10/12 md:w-1/2 ">
        <div className="min-h-50 md:min-h-60 lg:min-h-80 border border-[#9F9F9F] overflow-hidden  rounded-lg">
          <VideoPlayer
            className="w-max rounded-lg"
            videoURL="https://nncdn-fzddczfxfrdmhnat.z01.azurefd.net/nn-media-blob/Front%20Page%20Screenshot.mp4"
            style={{
              transform: "scale(1.22)",
              transformOrigin: "65% 45%",
            }}
          />
        </div>
        <a
          href="https://chromewebstore.google.com/detail/nerdninja/ndomankjolllpbijlfdgngibidghflln"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute left-1/2 transform -translate-x-1/2 font-roboto text-white text-xl md:text-2xl font-bold leading-3 md:leading-[1.365rem] px-3 md:px-4 py-1 md:py-2 -mb-7 bottom-0 flex items-center gap-2 add-to-chrome-btn"
        >
          Add to Chrome
          <img
            className="h-8 md:h-11 w-8 md:w-11"
            src="/images/chrome.png"
            alt="Profile"
          />
        </a>
      </div>
    </section>
  );
};

const Testimonial: React.FC = () => {
  return (
    <section id="Testimonial" className="my-10">
      <div className="relative mx-auto rounded-xl shadow-md rounded-lg border border-[#484848] bg-[#1D1D1D] w-3/4 md:w-2/3 ">
        <div className="absolute top-0 left-0">
          <img
            className="h-24 md:h-28 w-24 md:w-28 -translate-x-1/2 -translate-y-1/2 rounded-full object-cover"
            src="/images/Group 39389.png"
            alt="Profile"
          />
        </div>

        <div className="pt-6 px-4 md:px-8 pb-2 space-y-4">
          <div className="font-roboto text-white text-xl leading-[1.25rem] text-indent">
            Hey guys - James here. Over the past few months I’ve been working
            with a developer to create a quiz solving AI tool. I’m finally proud
            to announce <span className="inline text-[#AE01FF]">nerdNinja</span>
            <img
              className="mx-2 h-5 w-5 inline"
              src="/nerdNinja-star.png"
              alt="Logo"
            />
          </div>
          <div>
            <div className="font-roboto text-white text-xl leading-[1.25rem]">
              <span className="inline text-[#AE01FF]">nerdNinja is:</span>
              {""}
              Reliable, undetectable answers, smarter than ChatGPT, cheaper than
              a grande latte
            </div>
            <div className="font-roboto text-white text-xl leading-[1.25rem]">
              <span className="inline text-[#A9A9A9]">nerdNinja is not: </span>
              Ctrl-F’ing your textbook, A+ “guarantee”, the cure to cancer, or
              great at math…YET!
            </div>
          </div>
          <div className="font-roboto text-white text-xl leading-[1.25rem]">
            Although AI is scary good these days, I cannot with 100% certainty
            guarantee that nobody will encounter bugs. If you have any problems whatsoever,
            email me at james@nerdninja.io for a full refund. No questions asked.
          </div>

          <div className="font-roboto text-[#919090] text-md text-end md:text-xl leading-[1.138rem] flex justify-end items-center gap-2">
            <span className="block h-0.5 bg-gray-500 w-4"></span>
            <div className="flex flex-col md:flex-row gap-0 md:gap-2">
              <span className="inline">James Leonidas,</span>
              <span className="inline"> james@nerdninja.io</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const TryitForYourself: React.FC = () => {
  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  const [showLeftExplanation, setShowLeftExplanation] = useState(false);
  const [showRightExplanation, setShowRightExplanation] = useState(false);

  const [isLeftLoading, setIsLeftLoading] = useState(true);
  const [isRightLoading, setIsRightLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLeftLoading(false);
    }, 2000);

    // Clear the timer when component unmounts
    return () => clearTimeout(timer);
  }, [isLeftLoading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRightLoading(false);
    }, 2000);

    // Clear the timer when component unmounts
    return () => clearTimeout(timer);
  }, [isRightLoading]);

  const Q1 = {
    question:
      "Which of the following was the primary catalyst for the hostilities that began World War I?",
    correctAnswer: "The assassination of Archduke Franz Ferdinand",
    answers: {
      answerA: "An alliance system that pitted Germany against Russia",
      answerB: "The arms race between Britain and Germany",
      answerC: "The assassination of Archduke Franz Ferdinand",
      answerD: "The race for colonies between Germany and Britain in Africa",
    },
    explanation:
      "The spark that ignited World War I was when Archduke Franz Ferdinand was shot to death by the Serbian nationalist Gavrilo Princip on June 28, 1914.",
  };

  const Q2 = {
    question:
      'In"Catcher in the Rye"\n Why wasn\'t Holden at the big football game?',
    correctAnswer: "He was on academic probation",
    answers: {
      answerA: "He couldn’t Afford the ticket",
      answerB: "He was on academic probation",
      answerC: "He had to see his history teacher",
      answerD: "He had to finish a term paper that was due the next Monday",
    },
    explanation:
      "Holden wasn’t at the big football game because he met with his history teacher, for what he thinks is a final goodbye after he gets expelled from his school, Pencey Prep.",
  };

  const leftPopup = () => {
    setShowPopup1(!showPopup1);
    setIsLeftLoading(true);
    setShowLeftExplanation(false);
  };

  const rightPopup = () => {
    setShowPopup2(!showPopup2);
    setIsRightLoading(true);
    setShowRightExplanation(false);
  };

  const seeLeftExplanationHandler = () => {
    setShowLeftExplanation(!showLeftExplanation);
  };
  const seeRightExplanationHandler = () => {
    setShowRightExplanation(!showRightExplanation);
  };

  return (
    <section
      id="tryTtForYourself"
      className="py-20 flex flex-col items-center justify-center"
      // className="pt-60 pb-28 md:py-40 flex flex-col items-center justify-center"
    >
      <div className="w-10/12 relative">
        <span className="block relative pb-2">
          <h2 className="landing-header-shadow font-roboto text-4xl md:text-[64px] text-white text-center font-bold mb-2">
            Try it For Yourself
          </h2>
          <h3 className="font-robotoflex text-3xl md:text-4xl text-[#BBBBBB] text-center font-light mb-2">
            Our AI models stayed up all night reading, so you don’t have to
          </h3>
        </span>
        <div className="flex flex-col md:flex-row gap-8">
          {/* Start of left card */}
          <div className="order-last md:order-first z-10 w-full card-hover-ease-shadow custom-card flex flex-col justify-between px-5 md:px-8 pt-6 pb-2">
            <div className="flex gap-6">
              <p className="font-roboto text-white text-[22px] font-bold whitespace-pre-line">
                {Q1.question}
              </p>

              <div className="relative h-16 w-16 md:h-20 md:w-20 aspect-square rounded-full">
                <span
                  className="pulse cursor-pointer h-16 w-16 md:h-20 md:w-20 aspect-square rounded-full p-3 border-4 border-[#AE01FF] bg-black flex items-center justify-center"
                  onClick={leftPopup}
                >
                  <img className="" src="/nerdNinja-star.png" alt="Profile" />
                </span>
                {showPopup1 && (
                  <div className="bg-black absolute z-40 right-0 w-56 rounded-md shadow-lg">
                    {isLeftLoading ? (
                      <div className="flex items-center justify-center">
                        <img
                          src="/Spinner.svg"
                          alt="Spinner"
                          className="w-32 "
                        />
                      </div>
                    ) : (
                      <>
                        <div className="block flex justify-end">
                          <button
                            className="text-white font-bold mx-2"
                            onClick={leftPopup}
                          >
                            X
                          </button>
                        </div>
                        <div className="flex flex-col space-y-8 px-4">
                          <div className="block flex items-center justify-center gap-2">
                            <img
                              className="h-5 w-5 rounded-full object-cover bg-black"
                              src="/nerdNinja-star.png"
                              alt="Profile"
                            />
                            <p className="text-white text-xl">nerdNinja</p>
                          </div>

                          <p className="text-white">
                            <b> Solution:</b> {Q1.correctAnswer}
                          </p>

                          <div className="block flex justify-center">
                            <button
                              className={`${
                                showLeftExplanation ? "mb-0" : "mb-4"
                              } text-white border border-white px-2 py-1 rounded mx-2`}
                              onClick={seeLeftExplanationHandler}
                            >
                              See Explanation
                            </button>
                          </div>

                          <p
                            className={`${
                              showLeftExplanation ? "block" : "hidden"
                            } text-white mb-2 `}
                          >
                            {Q1.explanation}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col mb-4">
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer1A"
                  name="question1"
                  value={Q1.answers.answerA}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer1A"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q1.answers.answerA}
                </label>
              </div>
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer1B"
                  name="question1"
                  value={Q1.answers.answerB}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer1B"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q1.answers.answerB}
                </label>
              </div>
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer1C"
                  name="question1"
                  value={Q1.answers.answerC}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer1C"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q1.answers.answerC}
                </label>
              </div>
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer1D"
                  name="question1"
                  value={Q1.answers.answerD}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer1D"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q1.answers.answerD}
                </label>
              </div>
            </div>

            <p className="font-robotoLight text-sm md:text-base text-white italic font-light text-right">
              Real question from 2023 Oxford University Press History Quiz
            </p>
          </div>
          {/* End of left card */}

          {/* Start of Right card */}
          <div className="order-first md:order-last z-20 w-full card-hover-ease-shadow custom-card flex flex-col justify-between px-5 md:px-8 pt-6 pb-2">
            <div className="flex justify-between gap-6">
              <p className="font-roboto text-white text-[22px] font-bold whitespace-pre-line">
                {Q2.question}
              </p>

              <div className="relative h-16 w-16 md:h-20 md:w-20 aspect-square rounded-full">
                <span
                  className="pulse cursor-pointer h-16 w-16 md:h-20 md:w-20 aspect-square rounded-full p-3 border-4 border-[#AE01FF] bg-black flex items-center justify-center"
                  onClick={rightPopup}
                >
                  <img className="" src="/nerdNinja-star.png" alt="Profile" />
                </span>
                {showPopup2 && (
                  <div className="bg-black absolute z-50 right-0  w-56 rounded-md shadow-lg">
                    {isRightLoading ? (
                      <div className="flex items-center justify-center">
                        <img
                          src="/Spinner.svg"
                          alt="Spinner"
                          className="w-32"
                        />
                      </div>
                    ) : (
                      <>
                        <div className="block flex justify-end">
                          <button
                            className="text-white font-bold mx-2"
                            onClick={rightPopup}
                          >
                            X
                          </button>
                        </div>
                        <div className="flex flex-col space-y-8 px-4">
                          <div className="block flex items-center justify-center gap-2">
                            <img
                              className="h-5 w-5 rounded-full object-cover bg-black"
                              src="/nerdNinja-star.png"
                              alt="Profile"
                            />
                            <p className="text-white text-xl">nerdNinja</p>
                          </div>

                          <p className="text-white">
                            <b> Solution:</b> {Q2.correctAnswer}
                          </p>

                          <div className="block flex justify-center">
                            <button
                              className={`${
                                showRightExplanation ? "mb-0" : "mb-4"
                              } text-white border border-white px-2 py-1 rounded mx-2`}
                              onClick={seeRightExplanationHandler}
                            >
                              See Explanation
                            </button>
                          </div>

                          <p
                            className={`${
                              showRightExplanation ? "block" : "hidden"
                            } text-white mb-2 bg-black`}
                          >
                            {Q2.explanation}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col mb-4">
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer2A"
                  name="question2"
                  value={Q2.answers.answerA}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer2A"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q2.answers.answerA}
                </label>
              </div>
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer2B"
                  name="question2"
                  value={Q2.answers.answerB}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer2B"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q2.answers.answerB}
                </label>
              </div>
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer2C"
                  name="question2"
                  value={Q2.answers.answerC}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer2C"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q2.answers.answerC}
                </label>
              </div>
              <div className="flex items-center mb-2 gap-2 md:gap-3">
                <input
                  type="radio"
                  id="answer2D"
                  name="question2"
                  value={Q2.answers.answerD}
                  className="w-4 h-4 checked:appearance-none unchecked:appearance-radio rounded-full unchecked:bg-white custom-checkbox"
                />
                <label
                  htmlFor="answer2D"
                  className="w-11/12 font-robotoLight text-xl text-white"
                >
                  {Q2.answers.answerD}
                </label>
              </div>
            </div>

            <p className="font-robotoLight text-sm md:text-base text-white italic font-light text-right">
              Real question from High School English Quiz
            </p>
          </div>
          {/* End of Right card */}
        </div>
      </div>
    </section>
  );
};

const GetStartedSection: React.FC = () => {
  return (
    <section
      id="getStarted"
      className="py-20 flex flex-col items-center justify-center purple-background"
    >
      <h2 className="font-roboto text-5xl md:text-[64px] font-bold text-white text-center">
        Get Started
      </h2>
      <h3 className="font-robotoflex text-3xl md:text-[40px] text-[#E6E6E6] font-light text-center mb-4">
        Acing Your Tests.
      </h3>

      <div className="custom-card w-3/4 md:w-1/3 flex flex-col items-center card-hover-ease-shadow">
        <p className="text-white font-roboto text-2xl font-bold pt-5">
          Get your <u>first week</u> for
        </p>

        <p className="text-white font-roboto text-5xl md:text-[64px] font-extrabold pt-4">
          $0.99
        </p>

        <p className="text-white font-roboto text-xl italic">
          and then $7.99 / month
        </p>

        <Link
          to={"/signup"}
          className="get-started-button mt-4 py-2 px-6 text-white font-roboto text-[25.026px] font-semibold leading-[2.245rem] flex items-center gap-4"
        >
          Get Started
          <RightArrow />
        </Link>

        <p className="text-white text-center font-roboto text-base italic font-semibold leading-normal pt-6">
          Cancel Anytime
        </p>

        <span className="w-11/12 flex justify-between pb-2 pt-4">
          <span className="flex gap-2">
            <img src="/images/visa.png" alt="Logo" className="h-8" />
            <img src="/images/AM EX.png" alt="Logo" className="h-8" />
          </span>
          <span className="flex gap-2">
            <img src="/images/apple-pay.png" alt="Logo" className="h-8" />
            <img src="/images/mastercard.png" alt="Logo" className="h-8" />
          </span>
        </span>
      </div>
    </section>
  );
};

const Carousel: React.FC = () => {
  const settings = {
    dots: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    speed: 8000, // Adjust the speed of scrolling as needed
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 to make it scroll smoothly
    arrows: false, // Disable default arrows
    cssEase: "linear",
    pauseOnHover: false,
    // useCSS: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 2, // Show 2 slides on smaller screens
        },
      },
    ],
  };

  return (
    <>
      <div className="">
        <Slider {...settings} className="bg-[#1F1F1F]">
          <div className="relative h-28 w-28 md:w-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <img src="/images/Moodle.png" alt="Moodle" className="" />
            </div>
          </div>
          <div className="relative h-28 w-28 md:w-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <img src="/images/Blackboard.png" alt="Blackboard" className="" />
            </div>
          </div>
          <div className="relative h-28 w-28 md:w-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <img src="/images/D2L.png" alt="D2L" className="" />
            </div>
          </div>
          <div className="relative h-28 w-28 md:w-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                src="/images/Mc Graw Hill.png"
                alt="Mc Graw Hill"
                className=""
              />
            </div>
          </div>
          <div className="relative h-28 w-28 md:w-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <img src="/images/canvas.png" alt="Canvas" className="" />
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

const Section2: React.FC = () => {
  return (
    <section id="" className="w-full py-32">
      <div className="flex flex-col lg:flex-row justify-evenly gap-y-8 ">
        <span className="flex flex-col items-center gap-2">
          <p className="mb-2 text-white text-center font-roboto font-extrabold text-[32px] leading-[2.14rem] gradient-bg w-10 h-10 rounded-full flex items-center justify-center">
            1
          </p>
          <div className="rounded-xl relative">
            <div className="rounded-xl w-[310px] h-[416px]">
              <VideoPlayer
                className="rounded-xl w-[310px] h-[416px] object-cover"
                videoURL="https://nncdn-fzddczfxfrdmhnat.z01.azurefd.net/nn-media-blob/Step%201%20-%20Add%20to%20Chrome.mp4"
              />
            </div>

            <div className="absolute bottom-0 left-0 w-full flex items-stretch bg-gradient-to-b from-transparent to-black bg-opacity-20">
              <div className="flex justify-center items-end h-[416px] w-full">
                <p className="font-roboto text-white font-semibold text-2xl leading-10 text-center p-4">
                  Download Chrome Extension
                </p>
              </div>
            </div>
          </div>
        </span>

        <span className="flex flex-col items-center gap-2">
          <p className="mb-2 text-white text-center font-roboto font-extrabold text-[32px] leading-[2.14rem] gradient-bg w-10 h-10 rounded-full flex items-center justify-center">
            2
          </p>
          <div className="rounded-xl relative">
            <div className="rounded-xl w-[310px] h-[416px]">
              <VideoPlayer
                className="rounded-xl w-[310px] h-[416px] object-cover"
                videoURL="https://nncdn-fzddczfxfrdmhnat.z01.azurefd.net/nn-media-blob/Step%202%20-%20Login.mp4"
              />
            </div>

            <div className="absolute bottom-0 left-0 w-full flex items-stretch bg-gradient-to-b from-transparent to-black bg-opacity-20">
              <div className="flex justify-center items-end h-[416px] w-full">
                <p className="font-roboto text-white font-semibold text-2xl leading-10 text-center p-4">
                  Login with <br />
                  nerdNinja Account
                </p>
              </div>
            </div>
          </div>
        </span>

        <span className="flex flex-col items-center gap-2">
          <p className="mb-2 text-white text-center font-roboto font-extrabold text-[32px] leading-[2.14rem] gradient-bg w-10 h-10 rounded-full flex items-center justify-center">
            3
          </p>
          <div className="rounded-xl relative">
            <div className="rounded-xl w-[310px] h-[416px] relative overflow-hidden">
              <div style={{ overflow: "hidden", position: "relative" }}>
                <VideoPlayer
                  className="rounded-xl w-[310px] h-[416px]"
                  videoURL="https://nncdn-fzddczfxfrdmhnat.z01.azurefd.net/nn-media-blob/Step%203%20-%20Screenshot.mp4"
                  style={{
                    transform: "scale(2.5)",
                    transformOrigin: "10% 60%",
                    // pointerEvents: "none", // Disable pointer events on the video
                  }}
                />
              </div>
            </div>

            <div className="absolute bottom-0 left-0 w-full flex items-stretch bg-gradient-to-b from-transparent to-black bg-opacity-20">
              <div className="flex justify-center items-end h-[416px] w-full">
                <p className="font-roboto text-white font-semibold text-2xl leading-10 text-center p-4 w-full">
                  Screenshot
                  <br />
                  Questions
                </p>
              </div>
            </div>
          </div>
        </span>
      </div>
    </section>
  );
};

const FAQ: React.FC = () => {
  return (
    <section
      id="faq"
      className="flex flex-col justify-center items-center py-24"
    >
      <div className="w-11/12 md:w-2/3">
        <h2 className="landing-header-shadow font-roboto text-5xl md:text-[64px] font-bold text-white text-center">
          I bet you’re wondering...
        </h2>
        <h3 className="font-robotoflex text-2xl md:text-4xl text-[#979797] font-light text-center mb-8">
          Don’t worry, we covered all the bases
        </h3>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          <FAQItem
            question="How does nerdNinja work?"
            answer="nerdNinja is a highly sophisticated artificial intelligence algorithm. By feeding it large amounts of data, we can accurately predict answers to your questions."
          />
          <FAQItem
            question="Is nerdNinja accurate?"
            answer="nerdNinja is extremely accurate. As with any artificial intelligence, we cannot guarantee 100% accuracy. However we are working to improve accuracy with every update."
          />
          <FAQItem
            question="Is it detectable by my school software?"
            answer="Nope! Like the name implies, nerdNinja operates stealthily in the shadows…"
          />
          <FAQItem
            question="What is your cancellation policy?"
            answer="Cancel anytime! Just visit your “My Account” on the top right of the website. Enjoy the summer off and we’ll be here when you come back ;)"
          />
        </div>
      </div>
    </section>
  );
};

export default HomePage;
